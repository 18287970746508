html * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 15px;
    padding-top: 100px;
    font-family: Arial, Helvetica, sans-serif;
  }

ul {
    list-style: none;
    padding: 0;
}

.c-settings-item {
    margin-right: 15px;

    label {
        margin-right: 5px;
        font-weight: bold;
    }
}

.c-slider {
    &__input {
        width: 100%;
    } 
}

.c-note {
    &:before {
        display: inline-block;
        content: "|";
        margin: 0px 5px;
        color: black;
    }
}

.c-instrument-color {
    width: 10px;
    height: 100%;
}

.c-melody-vis {
    position: relative;
    display: flex;

    &__roll {
        width: 50px;
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        border-bottom: 0;
        position: relative;
        z-index: 2;
        background-color: white;
        opacity: 0.75;
    }

    &__key {
        padding: 3px;
        border-bottom: 1px solid black;
        text-align: center;
        font-size: 5px;
        display: block;
        position: relative;

        &--black {
            background-color: black;
        }
    }

    &__container {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    &__notes {
        display: flex;
        position: absolute;
        height: 100%;
        left: 50px;
        z-index: 1;
    }

    &__count {
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        border-bottom: 0;
    }

    &__slot {
        border-bottom: 1px solid black;
        text-align: center;
    }

    

    &__note {
        position: absolute;
        border: 1px solid black;
        background-color: black;
        z-index: 1;
    }
}

.c-nav {
    background-color: #fff;
    width: 100%;
    padding: 20px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.05);
}