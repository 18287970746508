html * {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 100px 15px 15px;
  font-family: Arial, Helvetica, sans-serif;
}

ul {
  padding: 0;
  list-style: none;
}

.c-settings-item {
  margin-right: 15px;
}

.c-settings-item label {
  margin-right: 5px;
  font-weight: bold;
}

.c-slider__input {
  width: 100%;
}

.c-note:before {
  content: "|";
  color: #000;
  margin: 0 5px;
  display: inline-block;
}

.c-instrument-color {
  width: 10px;
  height: 100%;
}

.c-melody-vis {
  display: flex;
  position: relative;
}

.c-melody-vis__roll {
  z-index: 2;
  opacity: .75;
  background-color: #fff;
  border: 1px solid #000;
  border-bottom: 0;
  flex-direction: column;
  width: 50px;
  display: flex;
  position: relative;
}

.c-melody-vis__key {
  text-align: center;
  border-bottom: 1px solid #000;
  padding: 3px;
  font-size: 5px;
  display: block;
  position: relative;
}

.c-melody-vis__key--black {
  background-color: #000;
}

.c-melody-vis__container {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.c-melody-vis__notes {
  z-index: 1;
  height: 100%;
  display: flex;
  position: absolute;
  left: 50px;
}

.c-melody-vis__count {
  border: 1px solid #000;
  border-bottom: 0;
  flex-direction: column;
  display: flex;
}

.c-melody-vis__slot {
  text-align: center;
  border-bottom: 1px solid #000;
}

.c-melody-vis__note {
  z-index: 1;
  background-color: #000;
  border: 1px solid #000;
  position: absolute;
}

.c-nav {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 12px #0000000d;
}

/*# sourceMappingURL=index.dd70d781.css.map */
